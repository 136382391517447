import React from 'react'
import './Booknow.style.css'

const Booknow = (setBookNowOpener) => {
    return (
        <>
            <div className="openit">
                <div className="openinside">
                    <h1>Thank you!</h1>
                    <p>Thanks for booking your flight with us. <br />you will receive a call from one of our representative soon</p>
                </div>
            </div>
        </>
    )
}

export default Booknow